import { IconButton, grid } from '@aceandtate/ds';
import styled, { css } from 'styled-components';
import { zIndexMasterList } from 'styles';

export const MuteButton = styled(IconButton)`
  span {
    width: unset;
  }
`;

export const VideoWrapper = styled.div<{ height?: number; aspect?: number; videoFit?: 'contain' | 'cover' }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 0;
  padding-bottom: ${({ aspect }) => `calc(${aspect} * 100%)`};

  & > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: ${({ videoFit }) => videoFit};
    object-position: center;
  }
`;

export const VideoButtonOverlay = styled.div<{
  isPlaying: boolean;
  muted: boolean;
}>`
  top: 0;
  left: 0;
  display: grid;
  height: 100%;
  position: absolute;
  width: 100%;
  cursor: pointer;
  padding: ${grid[40]};

  opacity: ${({ isPlaying, muted }) => (isPlaying && !muted ? 0 : 1)};
  transition: opacity 250ms ease-in-out;

  &:hover {
    opacity: 1;
  }

  place-items: ${({ isPlaying, muted }) => (isPlaying && muted ? 'end end' : 'center')};

  ${IconButton} {
    z-index: ${zIndexMasterList.videoPlayerButton + 1};
  }

  ${({ isPlaying, muted }) =>
    isPlaying &&
    muted &&
    css`
      ${IconButton} {
        color: white;
        padding: 0;
        height: 0;
        width: 0;
      }
    `}
`;

export const MutedGradientBackground = styled.div`
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: ${grid[120]};
  height: ${grid[120]};
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.66));
  z-index: ${zIndexMasterList.videoPlayerButton};
`;
