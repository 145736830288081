import { SchemaProp } from '@shopstory/core';

export const commonButtonSchema: SchemaProp[] = [
  {
    group: 'Button',
    prop: 'children',
    type: 'text'
  },
  {
    group: 'Button',
    options: ['default', 'outlined', 'link'],
    prop: 'variant',
    type: 'select'
  },
  {
    group: 'Button',
    options: ['primary', 'secondary', 'accent', 'error'],
    prop: 'color',
    type: 'select'
  },
  {
    group: 'Button',
    options: ['medium', 'small'],
    prop: 'size',
    type: 'select'
  }
];
