import styled from 'styled-components';
import { grid } from 'styles';
import { LandingPageCopyBlock } from 'types/contentful';

const gridPadding = 4;

function paddingOptions(input: string): number {
  switch (input) {
    case 'xs':
      return gridPadding;
    case 's':
      return gridPadding * 2;
    case 'm':
      return gridPadding * 4;
    case 'l':
      return gridPadding * 10;
    case 'xl':
      return gridPadding * 20;
    default:
      return 0;
  }
}

export const Body = styled.div<LandingPageCopyBlock>`
  margin: 0 30px;
  padding-bottom: ${props => paddingOptions(props.paddingBottom)}px;
  padding-top: ${props => paddingOptions(props.paddingTop)}px;
  text-align: ${props => props.textAlignment};

  p:empty {
    margin-bottom: 0;
    padding-bottom: ${grid.s};
  }
`;
