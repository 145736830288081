import { commonButtonSchema } from './commonButtonSchema';
import { ShopstoryPluginDefinition } from './helpers/getPluginDefinitions';

export const shopstoryPluginDefinitions: {
  item?: ShopstoryPluginDefinition[];
  section?: ShopstoryPluginDefinition[];
  card?: ShopstoryPluginDefinition[];
} = {
  item: [
    'giftcard-balance-check',
    {
      id: 'pupillary-distance',
      schema: [
        ...commonButtonSchema,
        {
          defaultValue: 'black',
          label: 'Result color',
          options: [
            { label: 'Dark', value: 'black' },
            { label: 'Light', value: 'white' }
          ],
          prop: 'resultColor',
          type: 'select'
        }
      ]
    },
    {
      id: 'cookie-policy',
      schema: [
        {
          label: 'Tracking Purpose',
          options: ['Advertising', 'Analytics', 'Essential', 'Functional'],
          prop: 'trackingPurpose',
          type: 'select'
        }
      ]
    },
    {
      id: 'landingpage-newsletter',
      label: 'Newsletter',
      schema: [
        {
          label: 'Color',
          options: ['dark', 'light'],
          prop: 'color',
          type: 'select'
        },
        {
          label: 'Subscription Type',
          options: ['landing page', 'reframe'],
          prop: 'subscriptionType',
          type: 'select'
        },
        {
          label: 'Alignment',
          options: ['left', 'center', 'right'],
          prop: 'align',
          type: 'select'
        }
      ]
    },
    {
      id: 'conformity-table',
      label: 'Conformity Table'
    }
  ],
  section: ['unidays-landingpage']
};
