import styled from 'styled-components';
import { breakpointRules, grid, Typography } from '@aceandtate/ds';

export const InnerAnswer = styled.div`
  display: flex;
  flex-flow: column;
  padding: ${grid[12]} ${grid.padding};

  @media ${breakpointRules.tablet} {
    padding: ${grid[24]} ${grid.padding};
  }

  @media ${breakpointRules.laptop} {
    padding: ${grid[12]} 0 ${grid[24]};
  }
`;

export const FAQBlockTitle = styled(Typography)`
  margin-bottom: ${grid[24]};

  @media ${breakpointRules.laptop} {
    margin: ${grid[12]} ${grid[24]} 0;
  }
`;

export const FaqContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
`;

export const FaqAnswer = styled.div<{
  open?: boolean;
  answerHeight: number;
}>`
  transition: all 300ms ease-out;
  overflow: hidden;
  opacity: ${({ open }) => (open ? 1 : 0)};
  max-height: ${({ open, answerHeight }) => (open ? `calc(${answerHeight}px + (2 * ${grid[24]}))` : 0)};
`;

export const FaqAccordion = styled.div`
  margin: 0 calc(-1 * ${grid.padding});
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &:first-of-type {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  @media ${breakpointRules.laptop} {
    margin: 0;

    &:first-of-type {
      border-top: none;
    }
  }
`;

export const FAQIcon = styled.div`
  & > svg {
    font-size: 20px;

    @media ${breakpointRules.laptop} {
      font-size: 24px;
    }
  }
`;

export const FaqQuestion = styled.button`
  display: flex;
  gap: ${grid[8]};
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  justify-content: space-between;
  padding: ${grid[12]} ${grid.padding};

  @media ${breakpointRules.laptop} {
    padding: ${grid[24]} 0;
    gap: ${grid[24]};
  }

  &:focus {
    background: rgba(0, 0, 0, 0.1);
    outline: none;
  }
`;
