import Plugins from 'components/Link/Plugins';
import { humanizePluginKey } from './humanizePluginKey';
import { SchemaProp, CustomComponent } from '@shopstory/core';

export type ShopstoryPluginDefinition =
  | keyof typeof Plugins
  | {
      id: keyof typeof Plugins | string;
      label?: string;
      schema?: SchemaProp[];
    };

type ShopstoryPluginGroups = {
  [key: string]: ShopstoryPluginDefinition[];
};

/**
 * Returns Shopstory components
 *
 * @params shopstoryPluginGroups - button, item, section & card groups containing list of plugin keys
 * @returns Link plugins as Shopstory component definitions
 */
export function getPluginDefinitions(shopstoryPluginGroups: ShopstoryPluginGroups): CustomComponent[] {
  return Object.keys(shopstoryPluginGroups).flatMap((groupName: CustomComponent['type']) => {
    const group: ShopstoryPluginDefinition[] = shopstoryPluginGroups[groupName];

    return group.flatMap(pluginDefinition => {
      const {
        id,
        schema = [],
        label = null
      } = typeof pluginDefinition === 'object' ? pluginDefinition : { id: pluginDefinition };

      return {
        builtinProps: {
          label: 'off'
        },
        id,
        label: label || `${humanizePluginKey(id)} Plugin`,
        schema,
        type: groupName
      };
    });
  });
}
