import { Document } from '@contentful/rich-text-types';
import React, { ReactElement } from 'react';
import { Grid, Cell } from '@aceandtate/ds';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';
import { onPageBannerClick } from 'tracking/utils';
import * as Styles from './styles';
import { LandingPageCopyBlockV2 } from 'types/contentful';

const buttonColorMap = {
  accent: 'accent',
  black: 'primary',
  white: 'secondary'
};

type Props = LandingPageCopyBlockV2 & {
  textAlign?: 'left' | 'center' | 'right';
  placeTextX?: 'left' | 'center' | 'right';
  body: Document;
};

export default function CopyV2({
  textAlign = 'center',
  placeTextX = 'center',
  body,
  buttonColor,
  ...props
}: Props): ReactElement {
  return (
    <Grid horizontalAlign={placeTextX}>
      <Cell columns={{ laptop: 12 / 1, mobile: 0 }} />
      <Cell columns={{ laptop: 2, mobile: 1 }}>
        <Styles.InnerCopyBlock textAlign={textAlign} onClickCapture={onPageBannerClick('copyBlockV2')} {...props}>
          {renderRichTextV2(body as Document, {
            buttonColor: buttonColorMap[buttonColor]
          })}
        </Styles.InnerCopyBlock>
      </Cell>
      <Cell columns={{ laptop: 12 / 1, mobile: 0 }} />
    </Grid>
  );
}
