import React, { ReactElement, ReactNode, useRef, useState } from 'react';
import { breakpointRules, IconButton, IconChevronLeft, IconChevronRight, useMediaQuery } from '@aceandtate/ds';
import * as Styles from './styles';

type Props = {
  /** Carousel items require setting forwardRef as ref to any element (eg: ref={forwardRef}) */
  children: ReactNode[];
  /** Toggle to enable/disable carousel behaviour (default: true) */
  carousel?: boolean;
  /** Amount of items to skip when clicking next/previous buttons (default: 3) */
  offset?: number;
  /** Toggle to show/hide carousel buttons (default: true) */
  showButtons?: boolean;
  horizontalAlign?: 'left' | 'center' | 'right';
  buttonColor?: 'primary' | 'secondary';
};

export default function MultiColumn({
  children,
  carousel = true,
  offset = 3,
  horizontalAlign = 'left',
  buttonColor = 'primary',
  showButtons = true
}: Props): ReactElement {
  const isTablet = useMediaQuery(breakpointRules.tablet);
  const isLaptop = useMediaQuery(breakpointRules.laptop);
  const isDesktop = useMediaQuery(breakpointRules.desktop);
  const displayCarouselButtons = showButtons && carousel && isLaptop && children.length > offset;

  const scrollParentRef = useRef(null);
  const nextItemRef = useRef(null);
  const prevItemRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const paddingOffset = (isDesktop && 90) || (isLaptop && 64) || (isTablet && 40) || 24;

  function scrollLeft() {
    if (currentIndex > 0 && prevItemRef.current) {
      scrollParentRef.current.scrollTo({
        behavior: 'smooth',
        left: prevItemRef.current.offsetLeft - paddingOffset
      });
      setCurrentIndex(Math.max(currentIndex - offset, 0));
    }
  }

  function scrollRight() {
    if (currentIndex < children.length - offset && nextItemRef.current) {
      scrollParentRef.current.scrollTo({
        behavior: 'smooth',
        left: nextItemRef.current?.offsetLeft - paddingOffset
      });
      setCurrentIndex(currentIndex + offset);
    }
  }

  const prevDisabled = currentIndex === 0;
  const nextDisabled = currentIndex > children.length - offset;

  return (
    <div style={{ position: 'relative' }}>
      {displayCarouselButtons && (
        <Styles.CarouselButtonWrapper>
          <IconButton variant='outlined' color={buttonColor} disabled={prevDisabled} onClick={scrollLeft}>
            <IconChevronLeft />
          </IconButton>
          <IconButton variant='outlined' color={buttonColor} disabled={nextDisabled} onClick={scrollRight}>
            <IconChevronRight />
          </IconButton>
        </Styles.CarouselButtonWrapper>
      )}
      {children?.length > 0 && (
        <Styles.ScrollParent ref={scrollParentRef}>
          <Styles.ScrollSlide horizontalAlign={horizontalAlign} carousel={carousel}>
            {React.Children.map(children, (child, i) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  forwardRef:
                    (i === currentIndex + offset ? nextItemRef : null) ||
                    (i === currentIndex - offset ? prevItemRef : null)
                } as any);
              }
              return child;
            })}
            {carousel && <Styles.ScrollMargin />}
          </Styles.ScrollSlide>
        </Styles.ScrollParent>
      )}
    </div>
  );
}
