import styled, { css } from 'styled-components';
import { breakpointRules, Typography } from '@aceandtate/ds';
import { grid } from 'styles';

export const LinkWrapperV2 = styled.span`
  pointer-events: auto;

  b {
    font-weight: inherit;
  }

  a {
    text-decoration: underline;

    :hover {
      opacity: 0.8;
    }
  }

  button {
    margin-top: ${grid.s};
  }

  &:not(:nth-last-child(2)) {
    button {
      margin-right: ${grid.s};
    }
  }
`;

export const ButtonWrapperV2 = styled.span`
  pointer-events: auto;
  b {
    font-weight: inherit;
  }

  i {
    font-style: inherit;
  }

  & > a,
  & > button {
    margin-top: ${grid.s};
    margin-right: ${grid.s};
  }
`;

export const RichTextV2ImageWrapper = styled.div``;

export const RichTextTypographyV2Body = styled(Typography)<{ hideMobile?: boolean; forwardedAs?: 'div' }>`
  &:last-child {
    margin-bottom: 0;
  }

  ${ButtonWrapperV2}:last-of-type {
    a,
    button {
      margin-right: 0;
    }
  }

  ${({ hideMobile }) =>
    hideMobile &&
    css`
      .body-text {
        display: none;

        @media ${breakpointRules.laptop} {
          display: unset;
        }
      }
    `}
`;

export const RichTextTypographyV2Caption = styled(Typography)`
  text-transform: uppercase;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const RichTextTypographyV2Heading = styled(Typography)``;
