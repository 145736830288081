import React, { ReactElement, useRef, useState } from 'react';
import * as Styles from './styles';
import { MdPause, MdOutlinePlayArrow, MdOutlineVolumeOff } from 'react-icons/md';
import { IconButton } from '@aceandtate/ds';

interface Props {
  videoPlayOptions?: 'autoPlay' | 'autoPlayWithButton';
  videoFit?: 'contain' | 'cover';
  poster?: string;
  contentType?: string;
  src: string;
  height?: number;
  aspect?: number;
}

export default function VideoPlayer({ src, contentType, poster, videoFit, ...props }: Props): ReactElement {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hasAutoplay = props.videoPlayOptions === 'autoPlay' || props.videoPlayOptions === 'autoPlayWithButton';

  const [videoIsPlaying, setVideoIsPlaying] = useState(!!hasAutoplay);
  const [videoMuted, setVideoMuted] = useState(true);

  function toggleVideoPlay() {
    const { current: currentVideo } = videoRef;

    if (props.videoPlayOptions === 'autoPlayWithButton' && videoMuted) {
      currentVideo.muted = false;
      setVideoMuted(false);
    } else if (props.videoPlayOptions !== 'autoPlay' && videoIsPlaying) {
      currentVideo.pause();
      setVideoIsPlaying(false);
    } else if (props.videoPlayOptions !== 'autoPlay') {
      currentVideo.play();
      currentVideo.muted = false;
      setVideoMuted(false);
      setVideoIsPlaying(true);
    }
  }

  function onVideoEnded() {
    // Seek back to the start of video
    setVideoIsPlaying(false);
    videoRef.current.currentTime = 0;
  }

  return (
    <Styles.VideoWrapper videoFit={videoFit} height={props.height} aspect={props.aspect}>
      <video
        autoPlay={hasAutoplay}
        loop={hasAutoplay}
        muted={hasAutoplay}
        onClick={toggleVideoPlay}
        onEnded={onVideoEnded}
        playsInline
        poster={poster}
        ref={videoRef}
      >
        <source src={src} type={contentType} />
      </video>
      {props.videoPlayOptions !== 'autoPlay' && (
        <Styles.VideoButtonOverlay onClick={toggleVideoPlay} isPlaying={videoIsPlaying} muted={videoMuted}>
          {videoIsPlaying && videoMuted ? (
            <>
              <Styles.MutedGradientBackground />
              <Styles.MuteButton>
                <MdOutlineVolumeOff size={27} />
              </Styles.MuteButton>
            </>
          ) : (
            <IconButton color='secondary'>
              {videoIsPlaying && !videoMuted && <MdPause size={27} />}
              {!videoIsPlaying && <MdOutlinePlayArrow size={27} />}
            </IconButton>
          )}
        </Styles.VideoButtonOverlay>
      )}
    </Styles.VideoWrapper>
  );
}
