import { grid, breakpointRules } from '@aceandtate/ds';
import styled, { css } from 'styled-components';
import { fadeUpAnimation } from 'styles';

// Banner Styles

const verticalAlignMap = {
  bottom: 'flex-end',
  center: 'center',
  top: 'flex-start'
};

export const BannerContent = styled.div<{
  textAlign: 'left' | 'center' | 'right';
  placeY: 'top' | 'center' | 'bottom';
}>`
  top: 0;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: ${({ textAlign }) => textAlign};
  align-items: ${({ placeY }) => verticalAlignMap[placeY]};
  padding: ${grid[40]} 0;

  @media ${breakpointRules.laptop} {
    align-items: center;
  }
`;

export const BannerWrapper = styled.div<{ animate?: boolean }>`
  width: 100%;
  position: relative;

  ${({ animate }) =>
    animate &&
    css`
      animation: 0.6s ease-out 0.3s 1 ${fadeUpAnimation};
      animation-fill-mode: both;
    `}
`;

export const HideMobile = styled.div`
  display: none;

  @media ${breakpointRules.laptop} {
    display: block;
  }
`;

export const HideLaptop = styled.div`
  display: block;

  @media ${breakpointRules.laptop} {
    display: none;
  }
`;
