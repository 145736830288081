import React, { ReactNode } from 'react';
import { Button, Typography, uiColors, FloatingDelayGroup } from '@aceandtate/ds';
import { ShopstoryProductCard as ProductCard } from './components/ShopstoryProductCard';
import Contentful from '../blocks/Contentful';
import { ShopstoryProvider } from '@shopstory/react';
import { parsePluginOptions } from 'components/Link';
import { captureException } from '@sentry/nextjs';
import { shopstoryPluginDefinitions } from './shopstoryPluginDefinitions';
import { getPluginDefinitions } from './helpers/getPluginDefinitions';
import UpsellBanner from './components/Banners/UpsellBanner';
import { ProductLink, QuickLink, URLRouteLinkProvider } from './components/Links';
import { ShopstoryImage } from './components/Images';
import { ShopstoryVideo } from './components/Videos';

const ContentfulBlockWrapper: React.FC<{ data: any }> = props => {
  return <Contentful blocks={[props?.data]} />;
};

function PluginButton(props) {
  const { plugin: pluginKey } = props;

  const { Plugin, props: pluginProps } = parsePluginOptions(pluginKey);

  if (typeof pluginKey === 'string' && typeof Plugin === 'function') {
    return <Plugin {...props} {...pluginProps} />;
  } else {
    captureException(`Missing Shopstory plugin definition for: ${pluginKey}`);

    return (
      <Typography as='span' color={uiColors.error} fontWeight='bold'>
        Missing plugin definition
      </Typography>
    );
  }
}

const pluginComponents = getPluginDefinitions(shopstoryPluginDefinitions).reduce(
  (obj, pluginDefinition) => ({ ...obj, [pluginDefinition.id]: parsePluginOptions(pluginDefinition.id)?.Plugin }),
  {}
);

type Props = {
  children: ReactNode;
};

export function IrisShopstoryProvider({ children }: Props) {
  return (
    <FloatingDelayGroup delay={120}>
      <ShopstoryProvider
        buttons={{
          Button, // uses old props (primary/secondary), hidden from editor
          ButtonV2: Button, // uses new props (dark/light)
          Plugin: PluginButton
        }}
        components={{
          LandingPageBlock: ContentfulBlockWrapper,
          ProductCard,
          UpsellBanner,
          ...pluginComponents
        }}
        links={{
          MyLink: URLRouteLinkProvider,
          ProductLink,
          QuickLink
        }}
        actions={{
          MyAction: (values: any) => {
            alert('action text: ' + values.someText);
          }
        }}
        Image={ShopstoryImage}
        Video={ShopstoryVideo}
      >
        {children}
      </ShopstoryProvider>
    </FloatingDelayGroup>
  );
}
