import { useIntersect } from '@aceandtate/ds';
import React, { LinkHTMLAttributes } from 'react';
import styled from 'styled-components';

const InvisibleSpan = styled.span`
  height: 0;
  width: 0;
  visibility: hidden;
`;

type Props = LinkHTMLAttributes<HTMLLinkElement> & {
  href: string;
  intersectOptions?: Parameters<typeof useIntersect>[0];
};

/** Automatically renders \<link rel="preload" /> when the item is in view */
export default function PreloadInView({ href, intersectOptions, ...props }: Props) {
  const { ref, isIntersecting } = useIntersect({
    defaultIntersect: false,
    ...intersectOptions
  });

  return (
    <InvisibleSpan aria-hidden ref={ref}>
      {isIntersecting ? <link rel='preload' href={href} {...props} /> : null}
    </InvisibleSpan>
  );
}
