import React from 'react';
import OptimizedCFImage from 'components/Images/OptimizedCtfImage';

export function ShopstoryImage(props: { src: string; alt?: string }) {
  return (
    <OptimizedCFImage
      image={{
        alt: props.alt,
        height: 1,
        src: props.src,
        width: 1
      }}
      fill
    />
  );
}
