import { Cell, Grid, breakpointRules, grid } from '@aceandtate/ds';
import styled, { css } from 'styled-components';
import { fadeUpAnimation } from 'styles';
import { BackgroundPaddingOptions } from '.';

export const BannerContent = styled.div<{
  textColor: string;
  textAlign: 'left' | 'center' | 'right';
  backgroundPadding: BackgroundPaddingOptions;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ textColor }) => textColor};
  text-align: ${({ textAlign }) => textAlign};
  padding: ${grid[24]} 0 0;

  & > div {
    width: 100%;
  }

  ${({ backgroundPadding }) => backgroundPadding === 'negative' && `margin-bottom: calc(-0.5 * 44px);`}

  @media ${breakpointRules.laptop} {
    padding: ${grid[40]} 0;
    margin-bottom: ${({ backgroundPadding }) => backgroundPadding === 'negative' && `0`};
  }
`;

export const BannerGrid = styled(Grid)`
  row-gap: 0;
`;

export const BannerWrapper = styled.div<{
  animate?: boolean;
  backgroundColor?: string;
  backgroundPadding: BackgroundPaddingOptions;
}>`
  width: 100%;
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}

  ${({ backgroundPadding }) =>
    backgroundPadding === 'negative' &&
    css`
      margin: ${grid.padding} 0 22px;
    `}

  @media ${breakpointRules.tablet} {
    ${({ backgroundPadding }) =>
      backgroundPadding === 'positive' &&
      css`
        padding-top: ${grid.padding};
        padding-bottom: ${grid.padding};
      `}
    ${({ backgroundPadding }) =>
      backgroundPadding === 'negative' &&
      css`
        margin-top: ${grid.padding};
        margin-bottom: 22px;
      `}

  @media ${breakpointRules.laptop} {
      ${({ backgroundPadding }) =>
        backgroundPadding === 'negative' &&
        css`
          margin-top: ${grid.padding};
          margin-bottom: ${grid.padding};
        `}
    }

    ${({ animate }) =>
      animate &&
      css`
        animation: 0.6s ease-out 0.3s 1 ${fadeUpAnimation};
        animation-fill-mode: both;
      `}
  }
`;

export const SideBannerImageWrap = styled(Cell)<{ backgroundPadding: BackgroundPaddingOptions }>`
  ${({ backgroundPadding }) => backgroundPadding === 'negative' && `margin-top: calc(-1 * ${grid.padding});`}

  @media ${breakpointRules.laptop} {
    ${({ backgroundPadding }) =>
      backgroundPadding === 'negative' &&
      `margin-top: calc(-1 * ${grid.padding}); margin-bottom: calc(-1 * ${grid.padding});`}
  }
`;
