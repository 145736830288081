import React, { ReactElement } from 'react';
import { Flattened } from 'services/contentful/helpers';
import { breakpointRules, useMediaQuery } from '@aceandtate/ds';
import { LandingPageMultiColumnBlockV2, LandingPageMultiColumnItemBlockV2 } from 'types/generated/contentful';
import MultiColumnItem, { MultiColumnLayout } from './MultiColumnItem';
import CardCarousel from 'blocks/Contentful/partials/Multicolumn/CardCarousel';
import * as Styles from './styles';

const buttonColorMap = {
  dark: 'primary',
  light: 'secondary'
};

type Props = Flattened<LandingPageMultiColumnBlockV2> & {
  layout: MultiColumnLayout;
};

export default function MultiColumnBlock({
  blocks,
  layout = 'big',
  carousel,
  textColor,
  carouselButtonColor
}: Props): ReactElement {
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  const displayAsCarousel = carousel === 'always' || (carousel === 'mobile' && !isLaptop);
  const carouselOffset = layout === 'big' ? 3 : 4;
  const alignCards = blocks.length < carouselOffset && !displayAsCarousel ? 'center' : 'left';

  return (
    <Styles.MultiColumnWrapper textColor={textColor}>
      {blocks?.length > 0 && (
        <CardCarousel
          buttonColor={buttonColorMap[carouselButtonColor]}
          horizontalAlign={alignCards}
          carousel={displayAsCarousel}
          offset={carouselOffset}
        >
          {blocks.map((block: LandingPageMultiColumnItemBlockV2 & { id: string }) => (
            <MultiColumnItem
              columns={displayAsCarousel && { big: { mobile: 1 + 1 / 3 } }}
              layout={layout}
              key={block.id}
              displayAsCarousel={displayAsCarousel}
              {...block}
            />
          ))}
        </CardCarousel>
      )}
    </Styles.MultiColumnWrapper>
  );
}
