import React from 'react';
import * as Styles from './styles';
import renderRichText from 'utils/contentful/renderRichText';
import type { LandingPageCollectionBlock, LandingPageCollectionItem } from 'types/contentful';
import type { Document } from '@contentful/rich-text-types';
import type { Flattened } from 'services/contentful/helpers';
import { onPageBannerClick } from 'tracking/utils';
import Media from 'components/Media';

const blocksPerRow = {
  'balanced twofer': 2,
  breaker: 2,
  'icon list': 4,
  quad: 4,
  triple: 3,
  twofer: 2
} as const;

const Components = {
  'balanced twofer': Styles.BalancedTwofer,
  breaker: Styles.Breaker,
  'icon list': Styles.IconList,
  quad: Styles.Quad,
  triple: Styles.Triple,
  twofer: Styles.Twofer
} as const;

// Array chunk function adapted from:
// https://ourcodeworld.com/articles/read/278/how-to-split-an-array-into-chunks-of-the-same-size-easily-in-javascript
function chunk<T>(arr: readonly T[], size: number) {
  const result: T[][] = [];
  for (let index = 0; index < arr.length; index += size) {
    result.push(arr.slice(index, index + size));
  }
  return result;
}

function itemHref(item: Flattened<LandingPageCollectionItem>) {
  const { href, urlRoute } = item;
  return urlRoute?.fields?.path || href;
}

export default function Collection({ type, blocks, ...props }: Flattened<LandingPageCollectionBlock>) {
  const rowSize = blocksPerRow[type] || blocksPerRow.twofer;
  const Container = Components[type] || Components.twofer;
  const rows = chunk(blocks, rowSize);

  return (
    <Container onClickCapture={onPageBannerClick('collectionBlock')} {...props}>
      {rows.map((row, index) => {
        return (
          <Styles.Row key={index} blockType={type}>
            {row.map(item => {
              const { media, mobileMedia, title, body, autoplay } = item;

              return (
                <Styles.Item key={item.id}>
                  <Styles.ItemWrapper>
                    <Styles.ItemLink to={itemHref(item)}>
                      {(mobileMedia || media) && (
                        <Media
                          autoPlay={autoplay}
                          landscape={media?.fields}
                          portrait={mobileMedia?.fields}
                          landscapeConfig={{ q: 80, w: 2200 }}
                          portraitConfig={{ q: 90, w: 800 }}
                          title={type !== 'icon list' && title}
                          animate
                        />
                      )}
                    </Styles.ItemLink>
                    <Styles.Caption>{renderRichText(body as Document)}</Styles.Caption>
                  </Styles.ItemWrapper>
                </Styles.Item>
              );
            })}
          </Styles.Row>
        );
      })}
    </Container>
  );
}
