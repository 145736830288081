import { Grid, breakpointRules, grid } from '@aceandtate/ds';
import styled, { css } from 'styled-components';
import { fadeUpAnimation } from 'styles';
import { BackgroundPaddingOptions } from '.';

export const BannerContent = styled.div<{
  textColor: string;
  textAlign: 'left' | 'center' | 'right';
}>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ textColor }) => textColor};
  text-align: ${({ textAlign }) => textAlign};
  padding: ${grid[24]} 0 ${grid[40]};

  @media ${breakpointRules.laptop} {
    padding: ${grid[40]} 0;
  }

  & > div {
    width: 100%;
  }
`;

export const BannerBackground = styled.div<{ backgroundPadding: BackgroundPaddingOptions; backgroundColor: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}

  @media ${breakpointRules.laptop} {
    left: auto;
    margin-left: calc(-1 * ${grid.gutter});

    ${({ backgroundPadding }) => {
      if (backgroundPadding === 'negative') {
        return css`
          height: calc(100% - (2 * ${grid.padding}));
          margin-top: ${grid.padding};
        `;
      } else if (backgroundPadding === 'positive') {
        return css`
          height: calc(100% + (2 * ${grid.padding}));
          margin-top: calc(-1 * ${grid.padding});
        `;
      }
    }};
  }
`;

export const InnerBannerWrapper = styled(Grid)`
  row-gap: 0;
`;

export const BannerWrapper = styled.div<{
  animate?: boolean;
  backgroundPadding: BackgroundPaddingOptions;
}>`
  position: relative;
  overflow: hidden;

  @media ${breakpointRules.laptop} {
    ${({ backgroundPadding }) => backgroundPadding === 'positive' && `margin: calc(${grid.padding}) 0;`}
  }

  ${({ animate }) =>
    animate &&
    css`
      animation: 0.6s ease-out 0.3s 1 ${fadeUpAnimation};
      animation-fill-mode: both;
    `}
`;

export const HalfBannerImageWrap = styled.div<{
  flip?: boolean;
  growToCenter?: boolean;
}>`
  position: relative;

  margin: 0 calc(-1 * ${grid.padding});
  @media ${breakpointRules.laptop} {
    margin: ${({ flip, growToCenter }) => {
      if (growToCenter) {
        return flip
          ? `0 calc(-1 * ${grid.padding}) 0 calc(-0.5 * ${grid.gutter})`
          : `0 calc(-0.5 * ${grid.gutter}) 0 calc(-1 * ${grid.padding})`;
      } else {
        return flip ? `0 calc(-1 * ${grid.padding}) 0 0` : `0 0 0 calc(-1 * ${grid.padding})`;
      }
    }};
  }
`;
