import styled, { css } from 'styled-components';
import { Typography } from '@aceandtate/ds';
import GenericLink from 'components/Link';
import { brandColors, breakpointRules, grid } from 'styles';
import type { LandingPageCollectionBlock } from 'types/contentful';
import type { Flattened } from 'services/contentful/helpers';

const gridGapPx = 20;
const gridColumns = 16;
const sideColumn = `calc(100vw / ${gridColumns + 2} - ${gridGapPx * 2}px)`;
const gridTemplateColumns = `${sideColumn} repeat(${gridColumns}, 1fr) ${sideColumn}`;
const tripleOrQuadBlockType = (type: string) => type === 'triple' || type === 'quad';

type RowProps = {
  blockType: string;
};

export const Container = styled.div<Flattened<LandingPageCollectionBlock>>`
  display: flex;
  flex-direction: column;
  text-align: ${props => props.textAlign || 'left'};
`;

export const ItemWrapper = styled.div`
  width: 100%;
  margin: 40px 0;
  @media ${breakpointRules.largeMin} {
    margin: 80px 0;
  }
`;

export const Row = styled.div<RowProps>`
  display: grid;
  grid-template-columns: ${gridTemplateColumns};
  grid-gap: 0 ${gridGapPx}px;
  justify-content: space-between;
  align-items: ${({ blockType }) => (tripleOrQuadBlockType(blockType) ? 'flex-start' : 'unset')};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  img,
  video {
    width: 100%;
    display: block;
    outline: none;
  }
`;

export const Caption = styled.div`
  margin-top: 15px;

  p:empty {
    margin-bottom: 0;
    padding-bottom: ${grid.s};
  }
`;

const inverted = (a: any, b: any) => (props: Flattened<LandingPageCollectionBlock>) => props.invert ? a : b;

export const IconList = styled(Container)<Flattened<LandingPageCollectionBlock>>`
  margin-top: ${grid.xl};
  ${ItemWrapper} {
    margin-top: 0;
  }
  ${Caption} {
    p {
      margin: 0;
    }
  }
  ${Row} {
    align-items: flex-start;
    @media ${breakpointRules.smallMax} {
      ${Item}:nth-child(4n+1) {
        grid-column: 2 / 8 span;
      }
      ${Item}:nth-child(4n+2) {
        grid-column: 8 span;
      }
      ${Item}:nth-child(4n+3) {
        grid-column: 2 / 8 span;
      }
      ${Item}:nth-child(4n+4) {
        grid-column: 8 span;
      }
    }
    @media ${breakpointRules.largeMin} {
      ${Item}:nth-child(4n+1) {
        grid-column: 4 / 3 span;
      }
      ${Item}:nth-child(4n+2) {
        grid-column: 3 span;
      }
      ${Item}:nth-child(4n+3) {
        grid-column: 3 span;
      }
      ${Item}:nth-child(4n+4) {
        grid-column: 3 span;
      }
    }
  }
`;

export const Twofer = styled(Container)<Flattened<LandingPageCollectionBlock>>`
  @media ${breakpointRules.smallMax} {
    ${Row} {
      ${Item}:first-child {
        grid-column: 3 / 14 span;
        grid-row: 1;
      }
      ${Item}:last-child {
        grid-column: 3 / 14 span;
        grid-row: 2;
      }

      ${({ mobileInvert }) =>
        mobileInvert &&
        css`
          ${Item}:first-child {
            grid-row: 2;
          }
          ${Item}:last-child {
            grid-row: 1;
          }
        `}
    }
  }
  @media ${breakpointRules.largeMin} {
    /* applies to desktop non-inverted breaker block (right item bigger) */
    ${Row}:nth-child(${inverted('even', 'odd')}) {
      ${Item}:first-child {
        grid-column: 3 / 6 span;
      }
      ${Item}:last-child {
        grid-column: 10 / 7 span;
      }
    }

    /* applies to desktop inverted breaker block (left item bigger) */
    ${Row}:nth-child(${inverted('odd', 'even')}) {
      ${Item}:first-child {
        grid-column: 3 / 7 span;
      }
      ${Item}:last-child {
        grid-column: 11 / 6 span;
      }
    }
  }
`;

export const BalancedTwofer = styled(Container)`
  @media ${breakpointRules.smallMax} {
    ${Row} {
      ${Item}:first-child {
        grid-column: 3 / 14 span;
        grid-row: 1;
      }
      ${Item}:last-child {
        grid-column: 3 / 14 span;
        grid-row: 2;
      }

      ${({ mobileInvert }) =>
        mobileInvert &&
        css`
          ${Item}:first-child {
            grid-row: 2;
          }
          ${Item}:last-child {
            grid-row: 1;
          }
        `}
    }
  }
  @media ${breakpointRules.largeMin} {
    ${Row} {
      ${Item}:first-child {
        grid-column: 3 / 7 span;
      }
      ${Item}:last-child {
        grid-column: 10 / 7 span;
      }
    }
  }
`;

export const ItemLink = styled(GenericLink)`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  color: ${brandColors.white};
`;

export const Link = styled(GenericLink)`
  border-bottom: 1px solid currentColor;
`;

export const Breaker = styled(Container)<Flattened<LandingPageCollectionBlock>>`
  @media ${breakpointRules.smallMax} {
    /* applies to mobile non-inverted block (top item always full width) */
    /* second child appears on top for mobile */
    ${Row}:nth-child(${inverted('even', 'odd')}) {
      ${Item}:first-child {
        grid-column: 3 / 14 span;
        grid-row: 2;
      }
      ${Item}:last-child {
        grid-column-start: 1;
        grid-column-end: 19;
        grid-row: 1;
        ${Caption} {
          /* Only for the value that goes to the edge  */
          margin-left: 10px;
        }
      }
    }
    /* applies to mobile inverted block (top item always full width) */
    ${Row}:nth-child(${inverted('odd', 'even')}) {
      ${Item}:first-child {
        grid-column-start: 1;
        grid-column-end: 19;
        ${Caption} {
          /* Only for the value that goes to the edge  */
          margin-left: 10px;
        }
      }
      ${Item}:last-child {
        grid-column: 3 / 14 span;
      }
    }
  }
  @media ${breakpointRules.largeMin} {
    /* applies to desktop non-inverted breaker block (right item bigger) */
    ${Row}:nth-child(${inverted('even', 'odd')}) {
      ${Item}:first-child {
        grid-column: 2 / 4 span;
      }
      ${Item}:last-child {
        grid-column: 7 / 12 span;
      }
    }
    /* applies to desktop inverted breaker block (left item bigger) */
    ${Row}:nth-child(${inverted('odd', 'even')}) {
      ${Item}:first-child {
        grid-column: 1 / 12 span;
        ${Caption} {
          /* Only for the value that goes to the edge  */
          margin-left: calc(${sideColumn} + ${gridGapPx}px);
        }
      }
      ${Item}:last-child {
        grid-column: 14 / 4 span;
      }
    }
  }
`;

export const Triple = styled(Container)`
  ${Row} {
    @media ${breakpointRules.smallMax} {
      ${({ mobileCarousel }) =>
        mobileCarousel
          ? css`
              display: flex;
              margin: 0 ${grid.m};
              flex-flow: row nowrap;
              max-width: 100%;
              overflow-x: scroll;

              & > * {
                flex: 0 0 max(35%, 260px);
                margin-right: ${grid.s};
                width: 10px;
              }
            `
          : css`
              ${Item}:nth-child(3n+1) {
                grid-column: 2 / 16 span;
                grid-row: 1;
              }
              ${Item}:nth-child(3n+2) {
                grid-column: 2 / 16 span;
                grid-row: 2;
              }
              ${Item}:nth-child(3n+3) {
                grid-column: 2 / 16 span;
                grid-row: 3;
              }
            `}
    }
    @media ${breakpointRules.largeMin} {
      grid-template-columns: 0 repeat(3, 1fr) 0;
      grid-gap: 0 ${gridGapPx * 3}px;
      ${Item}:nth-child(3n+1) {
        grid-column: 2;
      }
      ${Item}:nth-child(3n+2) {
        grid-column: 3;
      }
      ${Item}:nth-child(3n+3) {
        grid-column: 4;
      }
    }
  }
`;

export const Quad = styled(Container)`
  ${Row} {
    @media ${breakpointRules.smallMax} {
      ${({ mobileCarousel }) =>
        mobileCarousel
          ? css`
              display: flex;
              margin: 0 ${grid.m};
              flex-flow: row nowrap;
              max-width: 100%;
              overflow-x: scroll;

              & > * {
                flex: 0 0 max(35%, 260px);
                margin-right: ${grid.s};
                width: 10px;
              }
            `
          : css`
              ${Item}:nth-child(4n+1) {
                grid-column: 2 / 16 span;
                grid-row: 1;
              }
              ${Item}:nth-child(4n+2) {
                grid-column: 2 / 16 span;
                grid-row: 2;
              }
              ${Item}:nth-child(4n+3) {
                grid-column: 2 / 16 span;
                grid-row: 3;
              }
              ${Item}:nth-child(4n+4) {
                grid-column: 2 / 16 span;
                grid-row: 4;
              }
            `}
    }
    @media ${breakpointRules.largeMin} {
      ${Item}:nth-child(4n+1) {
        grid-column: 2 / 4 span;
      }
      ${Item}:nth-child(4n+2) {
        grid-column: 4 span;
      }
      ${Item}:nth-child(4n+3) {
        grid-column: 4 span;
      }
      ${Item}:nth-child(4n+4) {
        grid-column: 4 span;
      }
    }
  }
`;

export const Title = styled(Typography)`
  position: absolute;
`;
