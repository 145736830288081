import { Document } from '@contentful/rich-text-types';
import React, { ReactElement } from 'react';
import { Flattened } from 'services/contentful/helpers';
import { onPageBannerClick } from 'tracking/utils';
import { LandingPageSideImageBlockV2 } from 'types/contentful';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';
import { BackgroundPaddingOptions } from '.';
import * as Styles from './styles';

const buttonColorMap = {
  accent: 'accent',
  black: 'primary',
  white: 'secondary'
};

type Props = Flattened<LandingPageSideImageBlockV2> & {
  backgroundPadding: BackgroundPaddingOptions;
  textAlign?: 'left' | 'center' | 'right';
};

export default function SideImageContent({
  backgroundPadding,
  textColor,
  textAlign,
  buttonColor,
  body,
  ...props
}: Props): ReactElement {
  return (
    <Styles.BannerContent
      onClickCapture={onPageBannerClick('sideImageBlockV2Body')}
      backgroundPadding={backgroundPadding}
      textColor={textColor}
      textAlign={textAlign}
    >
      <div>
        {renderRichTextV2(body as Document, {
          buttonColor: buttonColorMap[buttonColor],
          hideMobile: props.hideMobileBodyText
        })}
      </div>
    </Styles.BannerContent>
  );
}
