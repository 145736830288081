import { Asset } from 'contentful';
import Image, { ImageProps } from 'next/image';
import React, { ReactElement } from 'react';
import { transform, TransformOptions } from 'utils/images';

export type ImageRaw = {
  src: string;
  width: number;
  height: number;
  alt?: string;
};

interface Props extends Omit<ImageProps, 'src' | 'alt'> {
  image: Asset | ImageRaw;
  sizes?: string;
  transformOptions?: TransformOptions;
}

function isImageRaw(arg: any): arg is ImageRaw {
  return (
    typeof arg === 'object' &&
    arg !== null &&
    typeof arg.src === 'string' &&
    typeof arg.width === 'number' &&
    typeof arg.height === 'number'
  );
}

function OptimizedCFImage({ image, sizes, transformOptions, ...props }: Props): ReactElement {
  const imageRaw: ImageRaw = isImageRaw(image)
    ? image
    : {
        alt: image.fields.description,
        height: image.fields.file.details.image.height,
        src: 'https:' + image.fields.file.url,
        width: image.fields.file.details.image.width
      };

  return (
    <Image
      src={imageRaw.src}
      alt={imageRaw.alt}
      quality={75}
      loader={({ width, quality, src }) => transform(src, { fit: 'scale-down', quality, width, ...transformOptions })}
      sizes={sizes}
      {...props}
      style={{
        ...{
          maxWidth: '100%'
        },
        ...props.style
      }}
    />
  );
}

export default OptimizedCFImage;
