import { grid, breakpointRules, Grid } from '@aceandtate/ds';
import styled from 'styled-components';

export const CarouselButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: calc(${grid[80]} * -1);
  position: absolute;
  width: 100%;
  padding-right: ${grid.padding};
  gap: ${grid[8]};
`;

export const ScrollParent = styled.div`
  overflow: auto hidden;
  row-gap: ${grid[40]};

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media ${breakpointRules.laptop} {
    row-gap: ${grid[60]};
  }
`;

export const ScrollSlide = styled(Grid)<{ carousel?: boolean }>`
  flex-wrap: ${({ carousel }) => (carousel ? 'nowrap' : 'wrap')};
  align-items: flex-start;
  row-gap: ${grid[60]};
`;

export const ScrollMargin = styled.div`
  flex-shrink: 0;
  height: 1px;
  width: calc(${grid.padding} - ${grid.gutter});
`;
