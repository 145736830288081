import React, { ReactElement } from 'react';
import { LandingPageSideImageBlockV2 } from 'types/contentful';
import { Flattened } from 'services/contentful/helpers';
import * as Styles from './styles';
import SideImageContent from './SideImageContent';
import SideImageMedia from './SideImageMedia';
import { Hideable } from 'blocks/Contentful/styles';
import { Cell } from '@aceandtate/ds';

type HorizontalPlacement = 'left' | 'center' | 'right';
export type BackgroundPaddingOptions = 'positive' | 'negative' | 'none';

type SideImageProps = Flattened<LandingPageSideImageBlockV2> & {
  textAlign?: HorizontalPlacement;
  bannerVariant?: 'square' | 'tall';
  videoPlayOptions?: 'autoPlay' | 'autoPlayWithButton';
  videoFit?: 'fit' | 'fill';
  backgroundPadding?: BackgroundPaddingOptions;
};

export default function SideImage({
  textColor = 'black',
  textAlign = 'left',
  bannerVariant = 'tall',
  videoFit = 'fill',
  backgroundPadding = 'none',
  backgroundColor = 'unset',
  buttonColor = 'black',
  buttonVariant = 'solid',
  flip = false,
  ...props
}: SideImageProps): ReactElement {
  const bannerLink = props.urlRoute?.fields?.path || props.link;

  return (
    <Styles.BannerWrapper backgroundPadding={backgroundPadding} backgroundColor={backgroundColor} animate>
      <Styles.BannerGrid>
        <Hideable as={Cell} columns={{ laptop: 3, mobile: 1 }} hide={{ laptop: !flip, mobile: true }}>
          <SideImageContent
            backgroundPadding={backgroundPadding}
            textAlign={textAlign}
            buttonColor={buttonColor}
            buttonVariant={buttonVariant}
            textColor={textColor}
            {...props}
          />
        </Hideable>
        <Hideable as={Cell} hide={{ laptop: !flip, mobile: true }} columns={{ laptop: 12 / 1 }} />

        <SideImageMedia
          backgroundPadding={backgroundPadding}
          videoFit={videoFit}
          bannerVariant={bannerVariant}
          bannerLink={bannerLink}
          {...props}
        />

        <Hideable as={Cell} hide={{ laptop: flip, mobile: true }} columns={{ laptop: 12 / 1 }} />
        <Hideable as={Cell} columns={{ laptop: 3, mobile: 1 }} hide={{ laptop: flip, mobile: false }}>
          <SideImageContent
            backgroundPadding={backgroundPadding}
            textAlign={textAlign}
            buttonColor={buttonColor}
            buttonVariant={buttonVariant}
            textColor={textColor}
            {...props}
          />
        </Hideable>
      </Styles.BannerGrid>
    </Styles.BannerWrapper>
  );
}
