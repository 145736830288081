import styled, { css } from 'styled-components';

export const InnerCopyBlock = styled.div<{
  textAlign?: 'left' | 'center' | 'right';
  textColor?: string;
}>`
  display: flex;
  flex-flow: column;
  color: ${({ textColor }) => textColor};

  /* Contentful adds an empty paragraph. To target the last item,
   we actually have to target the last two */
  & > *:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  ${({ textAlign }) => {
    if (textAlign === 'center') {
      return css`
        align-items: center;
        text-align: center;
      `;
    } else if (textAlign === 'right') {
      return css`
        align-items: flex-end;
        text-align: right;
      `;
    } else {
      return css`
        align-items: flex-start;
      `;
    }
  }}
`;
