import { Asset } from 'components/Campaigns/Banners/CampaignBanner/types';
import React, { ReactElement } from 'react';
import Image from 'next/image';
import VideoPlayer from 'components/VideoPlayer';
import { transform, transformHostOnly } from 'utils/images';

type ContentfulAssetConfig =
  | {
      quality?: number;
      width?: number;
      height?: number;
      aspect?: never;
    }
  | {
      quality?: number;
      width?: number;
      height?: never;
      aspect?: number;
    };

export type BannerMediaProps = {
  imageConfig: ContentfulAssetConfig;
  videoPlayOptions?: 'autoPlay' | 'autoPlayWithButton';
  videoFit?: 'contain' | 'cover';
  poster?: Asset['fields'];
  media?: Asset['fields'];
  /** Set approximate image size in vw or px (eg: 100vw), used to optimize image size */
  sizes: string;
  onClick?: () => void;
};

export default function BannerMedia({
  media,
  imageConfig,
  onClick,
  poster,
  videoFit,
  videoPlayOptions,
  sizes
}: BannerMediaProps): ReactElement {
  if (!media) return null;

  // Return <video> if media is a video
  const isVideoMedia = media?.file?.contentType.split('/')[0] === 'video';

  if (isVideoMedia) {
    return (
      <VideoPlayer
        videoPlayOptions={videoPlayOptions}
        videoFit={videoFit}
        src={transformHostOnly(media.file?.url)}
        contentType={media.file.contentType}
        poster={poster?.file.url}
        height={imageConfig.height}
        aspect={imageConfig.aspect}
      />
    );
  }

  const imageHeight = (width: number) => imageConfig.height || Math.round(width * imageConfig.aspect);

  // Return image if it isn't
  return (
    <Image
      onClick={onClick}
      src={'https:' + media.file?.url}
      height={imageHeight(imageConfig.width)}
      width={imageConfig.width}
      alt={media.description}
      quality={75}
      loader={({ width, quality, src }) => transform(src, { fit: 'cover', height: imageHeight(width), quality, width })}
      sizes={sizes}
      style={{
        width: '100%',
        height: 'auto'
      }}
    />
  );
}
