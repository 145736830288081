import React, { ReactNode } from 'react';
import { BLOCKS, INLINES, Block, Inline, Document, Text } from '@contentful/rich-text-types';
import { Options, documentToReactComponents, NodeRenderer } from '@contentful/rich-text-react-renderer';
import PluginLink, { parsePluginOptions } from 'components/Link';
import Media from 'components/Media';
import { Button, FontVariant, Typography } from '@aceandtate/ds';
import * as Sentry from '@sentry/nextjs';

import * as Styles from './stylesRichTextV2';
import { transform } from 'utils/images';
import Image from 'next/image';
import Link from 'next/link';

type Node = Block | Inline;
export type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const parserRules = {
  // eslint-disable-next-line no-useless-escape
  serif: { pattern: /[\*\_]{2}([^\*\_]+)[\*\_]{2}/g, transform: '<span class="font-serif">$1</span>' }
};

function parseChildren(children: ReactNode) {
  return React.Children.map(children, child =>
    typeof child === 'string' ? (
      <span
        dangerouslySetInnerHTML={{
          __html: child.replace(parserRules.serif.pattern, parserRules.serif.transform)
        }}
      />
    ) : (
      child
    )
  );
}

function renderHeading(node: Node, children: ReactNode, renderAs?: string) {
  try {
    // nodeTypes look like: "heading-3"
    const headerType = `h${node.nodeType.split('-').pop()}` as HeaderType;
    return (
      <Typography variant={headerType} gutterBottom as={renderAs || headerType}>
        {/* In order to allow text to be hyphenated, styled with serif font and/or words to be
        forced to remain together (via &nbsp; and &shy;) we need to pass
        the text thru dangerouslySetInnerHTML */}
        {parseChildren(children)}
      </Typography>
    );
  } catch (err) {
    return undefined;
  }
}

export function RenderLink(
  node: Node,
  children: ReactNode,
  props: any = { buttonColor: 'primary', buttonVariant: 'default' } // restores default behaviour on pages that are using renderRichText V1
) {
  if (node.content.every((text: Text) => text.value === '')) {
    return null;
  }

  const { nodeType: linkType } = node;
  const linkMap = {
    'asset-hyperlink': node.data.target?.fields?.file?.url,
    'entry-hyperlink': node.data.target?.fields?.path
  };
  const href = linkMap[linkType] || node.data.uri;

  const { content } = node;
  const innerContent = content.length === 1 && (content[0] as Text);
  const isButton = innerContent.marks.some(mark => mark.type === 'bold' || mark.type === 'italic');
  const buttonVariant: React.ComponentProps<typeof Button>['variant'] = innerContent.marks.some(
    mark => mark.type === 'italic'
  )
    ? 'outlined'
    : 'default';

  const { isPlugin } = parsePluginOptions(href);

  const assetProps =
    linkType === 'asset-hyperlink'
      ? {
          download: node.data.target.fields.file.fileName,
          rel: 'noopener noreferrer',
          target: '_blank'
        }
      : {};

  if (isPlugin) {
    return (
      <Styles.ButtonWrapperV2>
        <PluginLink
          href={href}
          buttonColor={props.buttonColor}
          buttonVariant={!isButton ? 'link' : buttonVariant}
          {...assetProps}
          {...props}
        >
          {children}
        </PluginLink>
      </Styles.ButtonWrapperV2>
    );
  } else {
    if (isButton) {
      return (
        <Styles.ButtonWrapperV2>
          <Link href={href || ''} passHref>
            <Button {...assetProps} color={props.buttonColor} variant={buttonVariant}>
              {parseChildren(children)}
            </Button>
          </Link>
        </Styles.ButtonWrapperV2>
      );
    }

    return (
      <Styles.LinkWrapperV2>
        <Link href={href || ''} {...assetProps}>
          {parseChildren(children)}
        </Link>
      </Styles.LinkWrapperV2>
    );
  }
}

function renderMedia(node: Node, _: ReactNode) {
  if (node.data.target.fields.file.contentType.includes('image/')) {
    const { title, file } = node.data.target.fields;
    const { width, height } = file.details.image;

    return (
      <Styles.RichTextV2ImageWrapper>
        <Image
          alt={title}
          title={title}
          width={width}
          height={height}
          src={`https:${node.data.target.fields.file.url}`}
          quality={75}
          loader={({ width, quality, src }) => transform(src, { quality, width })}
          sizes={'70vw'}
          style={{
            width: '100%',
            height: 'auto'
          }}
        />
      </Styles.RichTextV2ImageWrapper>
    );
  } else {
    return (
      <Media
        landscape={{ description: '', file: node.data.target.fields.file, title: node.data.target.fields.title }}
      />
    );
  }
}

function renderBody(node: Node, children: any[], props?: { hideMobile?: boolean; variant?: FontVariant }) {
  return (
    <Styles.RichTextTypographyV2Body
      hideMobile={props?.hideMobile}
      variant={props?.variant || 'bodyM'}
      color='inherit'
      gutterBottom
    >
      {React.Children.map(children, child => {
        if (typeof child === 'string') {
          /* In order to allow text to be styled with serif font we need to pass the text thru
          dangerouslySetInnerHTML */
          return (
            <span
              className='body-text'
              dangerouslySetInnerHTML={{
                __html: child.replace(parserRules.serif.pattern, parserRules.serif.transform)
              }}
            />
          );
        } else {
          return child;
        }
      })}
    </Styles.RichTextTypographyV2Body>
  );
}

function renderCaption(node: Node, children: ReactNode) {
  return (
    <Styles.RichTextTypographyV2Caption variant='bodyXS' color='inherit' gutterBottom>
      {parseChildren(children)}
    </Styles.RichTextTypographyV2Caption>
  );
}

const nodeTypeMap = {
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'heading-4': 'h4',
  'heading-5': 'h5',
  'heading-6': 'h6'
};

function renderListItem(node: Node, children: ReactNode) {
  const childNodeVariant = node.nodeType === 'list-item' && nodeTypeMap[node.content?.[0].nodeType];

  return (
    <Typography variant={childNodeVariant || 'bodyM'} as='li'>
      {children}
    </Typography>
  );
}

function guardException(fn: (...args: any[]) => any) {
  return (...args: any[]) => {
    try {
      return fn(...args);
    } catch (err) {
      Sentry.captureException(err, { extra: { args, fn: fn.name } });
      return null;
    }
  };
}

function options(options: RichTextOptions): Options {
  return {
    renderNode: {
      [BLOCKS.HEADING_1]: renderHeading,
      [BLOCKS.HEADING_2]: renderHeading,
      [BLOCKS.HEADING_3]: renderHeading,
      [BLOCKS.HEADING_4]: renderHeading,
      [BLOCKS.HEADING_5]: (node, children) =>
        renderHeading(node as Node, children, options?.optimizeForSeo ? 'h2' : null),
      [BLOCKS.HEADING_6]: renderCaption,
      [BLOCKS.PARAGRAPH]: renderBody,
      [BLOCKS.LIST_ITEM]: renderListItem,
      [BLOCKS.EMBEDDED_ASSET]: renderMedia
    }
  };
}

interface RichTextOptions {
  buttonColor?: React.ComponentProps<typeof Button>['color'];
  hideMobile?: boolean;
  bodySize?: FontVariant;
  optimizeForSeo?: boolean;
}

export default function renderRichTextV2(document: Document, richTextOptions?: RichTextOptions) {
  const { buttonColor = 'primary', hideMobile = false, bodySize = 'bodyM' } = richTextOptions || {};

  const additionalNodes: { [node: string]: NodeRenderer } = {
    [INLINES.HYPERLINK]: guardException((node, children) => RenderLink(node, children, { buttonColor })),
    [INLINES.ASSET_HYPERLINK]: guardException((node, children) => RenderLink(node, children, { buttonColor })),
    [INLINES.ENTRY_HYPERLINK]: guardException((node, children) => RenderLink(node, children, { buttonColor }))
  };

  if (bodySize !== 'bodyM' || hideMobile) {
    additionalNodes[BLOCKS.PARAGRAPH] = (node, children: any) =>
      renderBody(node as Node, children, { hideMobile, variant: bodySize });
  }

  return documentToReactComponents(document, {
    renderNode: { ...options(richTextOptions).renderNode, ...additionalNodes }
  });
}
