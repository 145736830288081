import React, { ReactElement, Ref } from 'react';
import * as Styles from './styles';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';
import { LandingPageMultiColumnBlockV2, LandingPageMultiColumnItemBlockV2 } from 'types/contentful';
import { Document } from '@contentful/rich-text-types';
import { breakpointRules, ResponsiveInput, Cell, useMediaQuery } from '@aceandtate/ds';
import { onPageBannerClick } from 'tracking/utils';
import { transform } from 'utils/images';
import ConditionalLink from 'components/ConditionalLink';

export type MultiColumnLayout = 'small' | 'big';

type MultiColumnItemProps = LandingPageMultiColumnItemBlockV2 &
  Partial<LandingPageMultiColumnBlockV2> & {
    layout: MultiColumnLayout;
    columns?: {
      big?: Partial<ResponsiveInput<number>>;
      small?: Partial<ResponsiveInput<number>>;
    };
    displayAsCarousel?: boolean;
    forwardRef?: Ref<any>;
  };

const defaultColumns = {
  big: {
    laptop: 3,
    mobile: 1,
    tablet: 2
  },
  small: {
    laptop: 4,
    mobile: 2,
    tablet: 3
  }
};

export default function MultiColumnItem({
  body,
  layout,
  displayAsCarousel,
  columns,
  forwardRef,
  ...props
}: MultiColumnItemProps): ReactElement {
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  const bannerLink = props.urlRoute?.fields?.path || props.href;
  const columnLayout = {
    big: { ...defaultColumns.big, ...columns.big },
    small: { ...defaultColumns.small, ...columns.small }
  };
  const media = isLaptop
    ? props.media?.fields || props.mobileMedia?.fields
    : props.mobileMedia?.fields || props.media?.fields;

  return (
    <Cell columns={columnLayout[layout]}>
      <div ref={forwardRef} />
      {media && (
        <ConditionalLink href={bannerLink} onClickCapture={onPageBannerClick('multiColumnBlockV2Image')}>
          <Styles.MultiColImage
            width={media.file.details.image.width}
            height={media.file.details.image.height}
            src={media.file?.url}
            alt={media.description}
            sizes={layout === 'big' ? '33vw' : '25vw'}
            quality={75}
            loader={({ width, quality, src }) => transform(src, { quality, width })}
          />
        </ConditionalLink>
      )}
      <Styles.MultiColumnBody
        onClickCapture={onPageBannerClick('multiColumnBlockV2Body')}
        displayAsCarousel={displayAsCarousel}
        layout={layout}
      >
        {renderRichTextV2(body as Document)}
      </Styles.MultiColumnBody>
    </Cell>
  );
}
