import React from 'react';
import * as Styles from './styles';
import { Grid, Cell, grid } from '@aceandtate/ds';
import type { Document } from '@contentful/rich-text-types';
import type * as ContentfulTypes from 'types/contentful';
import renderRichText from 'utils/contentful/renderRichText';
import { onPageBannerClick } from 'tracking/utils';
import styled from 'styled-components';

const StyledCell = styled(Cell)`
  padding: ${grid[16]} 0;

  p:empty {
    padding: 0;
  }
`;

const justifyMap = {
  center: 'center',
  left: 'left',
  right: 'right'
} as const;

const containerTypeColumnSpanMap = {
  body: {
    lg: 7,
    xl: 5
  },
  header: {
    lg: 11,
    xl: 10
  }
} as const;

export default function Copy(props: ContentfulTypes.LandingPageCopyBlock) {
  const justify = justifyMap[props.gridAlignment];
  const columnSpan = containerTypeColumnSpanMap[props.containerType || 'body'];

  return (
    <Grid borderless horizontalAlign={justify}>
      <StyledCell columns={{ desktop: 12 / columnSpan.xl, laptop: 12 / columnSpan.lg, mobile: 1 }}>
        <Styles.Body onClickCapture={onPageBannerClick('copyBlock')} {...props}>
          {renderRichText(props.body as Document)}
        </Styles.Body>
      </StyledCell>
    </Grid>
  );
}
