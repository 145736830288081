import { Entry } from 'contentful';
import { Cell, Grid } from '@aceandtate/ds';
import React, { useEffect, useState } from 'react';
import type * as ContentfulTypes from 'types/contentful';
import { FaqQuestion } from 'types/contentful';
import { FAQV2Question } from '.';
import * as Styles from './styles';

type Props = ContentfulTypes.LandingPageFaqBlockV2;

export default function FAQV2Block({ questions, title }: Props) {
  const [openSlug, setOpenSlug] = useState('');

  function handleHashChange() {
    const { hash } = window.location;
    setOpenSlug(hash.replace('#', ''));
  }

  useEffect(() => {
    handleHashChange();
    const { hash } = window.location;
    const element = hash && document.querySelector(`[data-slug="${hash.replace('#', '')}"]`);
    element && element.scrollIntoView({ behavior: 'smooth' });

    window.addEventListener('hashchange', handleHashChange, false);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  function removeHash() {
    history.pushState('', document.title, window.location.pathname + window.location.search);
  }

  function toggleOpen(slug: string) {
    if (openSlug === slug) {
      removeHash();
      handleHashChange();
    } else {
      window.location.hash = '#' + slug;
    }
  }

  return (
    <Styles.FaqContainer>
      <Grid verticalAlign='top'>
        <Cell columns={{ laptop: 12 / 4, mobile: 1 }}>
          <Styles.FAQBlockTitle variant='h3' gutterBottom>
            {title}
          </Styles.FAQBlockTitle>
        </Cell>
        <Cell columns={{ laptop: 12 / 8, mobile: 1 }}>
          {questions
            .filter(question => question.fields)
            .map((question: Entry<FaqQuestion>, index) => {
              return (
                <FAQV2Question
                  open={openSlug === question.fields?.slug}
                  key={index}
                  toggleOpen={toggleOpen}
                  {...question.fields}
                />
              );
            })}
        </Cell>
      </Grid>
    </Styles.FaqContainer>
  );
}
