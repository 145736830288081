import React from 'react';
import { Grid, Cell } from '@aceandtate/ds';
import type { Document } from '@contentful/rich-text-types';
import renderRichText from 'utils/contentful/renderRichText';
import type { LandingPageBannerBlock } from 'types/contentful';
import type { Flattened } from 'services/contentful/helpers';
import Media from 'components/Media';
import ConditionalLink from 'components/ConditionalLink';
import { onPageBannerClick } from 'tracking/utils';
import * as Styles from './styles';

const gridColumnSpan = {
  fullWidth: 12,
  medium: 10,
  small: 8
} as const;

export default function Banner(props: Flattened<LandingPageBannerBlock>) {
  const md = gridColumnSpan[props.gridWidth];
  const autoPlay = props.videoPlayOptions === 'autoPlay';
  const autoPlayWithButton = props.videoPlayOptions === 'autoPlayWithButton';
  const bannerLink = props.urlRoute?.fields?.path || props.link;

  return (
    <Styles.GridContainer>
      <Grid borderless horizontalAlign='center'>
        <Cell columns={{ laptop: 12 / md, mobile: 12 / gridColumnSpan.fullWidth }}>
          <Styles.BannerContainer {...props}>
            <ConditionalLink href={bannerLink} onClickCapture={onPageBannerClick('bannerBlockImage')}>
              <Styles.ItemLink>
                <Media
                  autoPlay={autoPlay}
                  autoPlayWithButton={autoPlayWithButton}
                  landscape={props.mediaLandscape?.fields}
                  portrait={props.mediaPortrait?.fields}
                  landscapeConfig={{ q: 80, w: 2200 }}
                  landscapePoster={props.placeholderLandscape?.fields}
                  portraitPoster={props.placeholderPortrait?.fields}
                  playButtonColor={props.playButtonColor}
                  title={props.title}
                  titleTextColor={props.titleTextColor}
                  animate
                />
              </Styles.ItemLink>
            </ConditionalLink>
          </Styles.BannerContainer>
        </Cell>
      </Grid>
      <Styles.BodyContainer onClickCapture={onPageBannerClick('bannerBlockBody')} {...props}>
        <Grid borderless verticalAlign='center'>
          <Cell columns={{ mobile: 12 / gridColumnSpan.fullWidth, tablet: 12 / md }}>
            {renderRichText(props.body as Document)}
          </Cell>
        </Grid>
      </Styles.BodyContainer>
    </Styles.GridContainer>
  );
}
