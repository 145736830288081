import React, { ReactElement } from 'react';
import { Cell, Grid } from '@aceandtate/ds';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';
import ConditionalLink from 'components/ConditionalLink';
import type { Document } from '@contentful/rich-text-types';
import { LandingPageHalfBannerBlockV2 } from 'types/contentful';
import { Flattened } from 'services/contentful/helpers';
import * as Styles from './styles';
import BannerMedia from '../BannerMedia';
import { onPageBannerClick } from 'tracking/utils';
import { Hideable } from 'blocks/Contentful/styles';

const aspectRatioMap = {
  short: {
    narrow: 7 / 10,
    wide: 1 / 2
  },
  medium: {
    narrow: 64 / 75,
    wide: 63 / 89
  },
  tall: {
    narrow: 11 / 10,
    wide: 9 / 10
  }
};

const videoFitMap = {
  fill: 'cover',
  fit: 'contain'
};

type HorizontalPlacement = 'left' | 'center' | 'right';
export type BackgroundPaddingOptions = 'positive' | 'negative' | 'none';

type HalfBannerV2Props = Flattened<LandingPageHalfBannerBlockV2> & {
  textAlign?: HorizontalPlacement;
  bannerVariant?: 'short' | 'tall';
  videoPlayOptions?: 'autoPlay' | 'autoPlayWithButton';
  videoFit?: 'fit' | 'fill';
  backgroundPadding?: BackgroundPaddingOptions;
};

const buttonColorMap = {
  accent: 'accent',
  black: 'primary',
  white: 'secondary'
};

export default function HalfBannerV2({
  textColor = 'black',
  textAlign = 'center',
  bannerVariant = 'short',
  videoFit = 'fill',
  videoPlayOptions,
  backgroundPadding = 'none',
  backgroundColor = 'unset',
  buttonColor = 'white',
  body,
  ...props
}: HalfBannerV2Props): ReactElement {
  const bannerLink = props.urlRoute?.fields?.path || props.link;

  const isVideoMedia =
    (props.mediaPortrait || props.mediaLandscape)?.fields.file?.contentType.split('/')[0] === 'video';

  return (
    <Styles.BannerWrapper backgroundPadding={backgroundPadding} animate {...props}>
      <Grid columns={{ laptop: 2, mobile: 1 }} horizontalAlign='right'>
        <Cell>
          <Styles.BannerBackground backgroundColor={backgroundColor} backgroundPadding={backgroundPadding} />
        </Cell>
      </Grid>
      <Styles.InnerBannerWrapper columns={{ laptop: 2, mobile: 1 }} horizontalAlign='left'>
        <Hideable as={Cell} hide={{ laptop: props.flip }}>
          <Styles.HalfBannerImageWrap growToCenter={props.growToCenter} flip={props.flip}>
            <ConditionalLink
              isLink={bannerLink && !isVideoMedia}
              href={bannerLink}
              onClickCapture={onPageBannerClick('halfBannerBlockV2Image')}
            >
              <Hideable hide={{ laptop: true }}>
                <BannerMedia
                  sizes='100vw'
                  videoFit={videoFitMap[videoFit] as 'cover' | 'contain'}
                  videoPlayOptions={videoPlayOptions}
                  imageConfig={{ aspect: aspectRatioMap[bannerVariant].narrow, width: 1100 }}
                  media={props.mediaPortrait?.fields || props.mediaLandscape?.fields}
                  poster={props.placeholderPortrait?.fields || props.placeholderLandscape?.fields}
                />
              </Hideable>
              <Hideable hide={{ laptop: false, mobile: true }} display='flex'>
                <BannerMedia
                  sizes='50vw'
                  videoFit={videoFitMap[videoFit] as 'cover' | 'contain'}
                  videoPlayOptions={videoPlayOptions}
                  imageConfig={{ aspect: aspectRatioMap[bannerVariant].wide, width: 1100 }}
                  media={props.mediaLandscape?.fields || props.mediaPortrait?.fields}
                  poster={props.placeholderLandscape?.fields || props.placeholderPortrait?.fields}
                />
              </Hideable>
            </ConditionalLink>
          </Styles.HalfBannerImageWrap>
        </Hideable>
        <Cell columns={{ laptop: 12 / 1 }} />
        <Cell columns={{ laptop: 12 / 4, mobile: 1, tablet: 1 }}>
          <Styles.BannerContent
            onClickCapture={onPageBannerClick('halfBannerBlockV2Body')}
            textColor={textColor}
            textAlign={textAlign}
          >
            <div>
              {renderRichTextV2(body as Document, {
                buttonColor: buttonColorMap[buttonColor]
              })}
            </div>
          </Styles.BannerContent>
        </Cell>
        <Cell columns={{ mobile: 12 / 1 }} />
        <Hideable as={Cell} hide={{ laptop: !props.flip, mobile: true }}>
          <Styles.HalfBannerImageWrap growToCenter={props.growToCenter} flip={props.flip}>
            <ConditionalLink isLink={bannerLink && !isVideoMedia} href={bannerLink} style={{ display: 'flex' }}>
              <a onClickCapture={onPageBannerClick('halfBannerBlockV2Image')} style={{ display: 'flex' }}>
                <BannerMedia
                  sizes='50vw'
                  videoFit={videoFitMap[videoFit] as 'cover' | 'contain'}
                  videoPlayOptions={videoPlayOptions}
                  imageConfig={{ aspect: aspectRatioMap[bannerVariant].wide, width: 1100 }}
                  media={props.mediaLandscape?.fields || props.mediaPortrait?.fields}
                  poster={props.placeholderLandscape?.fields || props.placeholderPortrait?.fields}
                />
              </a>
            </ConditionalLink>
          </Styles.HalfBannerImageWrap>
        </Hideable>
      </Styles.InnerBannerWrapper>
    </Styles.BannerWrapper>
  );
}
