import styled from 'styled-components';
import { Typography } from '@aceandtate/ds';
import { grid } from 'styles';

export const LinkWrapper = styled.span`
  a {
    letter-spacing: 0.003em;
    display: inline;
    transition: all ease-in-out 0.1s;
    text-decoration: underline;

    :hover {
      opacity: 0.8;
    }
  }
`;

export const ButtonWrapper = styled.span`
  a,
  button {
    margin-top: ${grid.s};
  }
`;

export const RichTextTypographyHeading = styled(Typography)``;

export const RichTextTypographyBody = styled(Typography).attrs({ forwardedAs: 'div' })``;
