import React from 'react';
import dynamic from 'next/dynamic';
import { SkeletonBox } from 'components/Loaders/Skeleton/SkeletonBox';

const bannerTypes = {
  polarized: dynamic(() => import('./partials/PolarizedBanner'), {
    loading: () => <SkeletonBox ratio='2/1' />
  }),
  premium: dynamic(() => import('./partials/PremiumBanner'), {
    loading: () => <SkeletonBox ratio='2/1' />
  })
};

function UpsellBanner({ type }) {
  const Banner = bannerTypes[type];
  return Banner ? <Banner /> : null;
}

export default UpsellBanner;
