import React from 'react';
import * as Styles from './styles';
import type { Document } from '@contentful/rich-text-types';
import type * as ContentfulTypes from 'types/contentful';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';

export default function Legal(props: ContentfulTypes.LegalPageBlock) {
  return (
    <Styles.LegalWrapper {...props}>
      <Styles.LegalCell>
        <Styles.Body {...props}>{renderRichTextV2(props.contentBody as Document)}</Styles.Body>
      </Styles.LegalCell>
    </Styles.LegalWrapper>
  );
}
