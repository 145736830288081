import React, { ReactElement } from 'react';
import { Grid, Cell } from '@aceandtate/ds';
import BannerMedia from '../BannerMedia';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';
import type { Document } from '@contentful/rich-text-types';
import { LandingPageBannerBlockV2 } from 'types/contentful';
import { Flattened } from 'services/contentful/helpers';
import * as Styles from './styles';
import { onPageBannerClick } from 'tracking/utils';
import { Hideable } from 'blocks/Contentful/styles';
import ConditionalLink from 'components/ConditionalLink';

const aspectRatioMap = {
  short: {
    narrow: 4 / 5,
    wide: 1 / 4
  },
  medium: {
    narrow: 6 / 5,
    wide: 7 / 20
  },
  tall: {
    narrow: 7 / 5,
    wide: 4 / 9
  }
};

const videoFitMap = {
  fill: 'cover',
  fit: 'contain'
};

type HorizontalPlacement = 'left' | 'center' | 'right';
type VerticalPlacement = 'top' | 'center' | 'bottom';

type BannerV2Props = Flattened<LandingPageBannerBlockV2> & {
  textAlign?: HorizontalPlacement;
  placeTextX?: HorizontalPlacement;
  placeTextY?: VerticalPlacement;
  bannerVariant?: 'short' | 'tall';
  videoPlayOptions?: 'autoPlay' | 'autoPlayWithButton';
  videoFit?: 'fit' | 'fill';
};

const buttonColorMap = {
  accent: 'accent',
  black: 'primary',
  white: 'secondary'
};

export default function BannerV2({
  textColor = 'black',
  textAlign = 'center',
  placeTextX = 'center',
  placeTextY = 'center',
  bannerVariant = 'short',
  videoFit = 'fill',
  videoPlayOptions,
  buttonColor = 'white',
  body,
  ...props
}: BannerV2Props): ReactElement {
  const bannerLink = props.urlRoute?.fields?.path || props.link;

  const isVideoMedia =
    (props.mediaPortrait || props.mediaLandscape)?.fields.file?.contentType.split('/')[0] === 'video';

  const isLink = bannerLink && !isVideoMedia;

  return (
    <Styles.BannerWrapper animate {...props}>
      <Hideable hide={{ laptop: false, mobile: true }}>
        <BannerMedia
          videoFit={videoFitMap[videoFit] as 'cover' | 'contain'}
          videoPlayOptions={videoPlayOptions}
          sizes='100vw'
          imageConfig={{ aspect: aspectRatioMap[bannerVariant].wide, width: 1080 }}
          media={props.mediaLandscape?.fields || props.mediaPortrait?.fields}
          poster={props.placeholderLandscape?.fields || props.placeholderPortrait?.fields}
        />
      </Hideable>
      <Hideable hide={{ laptop: true, mobile: false }}>
        <BannerMedia
          videoFit={videoFitMap[videoFit] as 'cover' | 'contain'}
          videoPlayOptions={videoPlayOptions}
          sizes='100vw'
          imageConfig={{ aspect: aspectRatioMap[bannerVariant].narrow, width: 1080 }}
          media={props.mediaPortrait?.fields || props.mediaLandscape?.fields}
          poster={props.placeholderPortrait?.fields || props.placeholderLandscape?.fields}
        />
      </Hideable>
      <ConditionalLink isLink={isLink} href={bannerLink} onClickCapture={onPageBannerClick('bannerBlockV2Image')}>
        <Styles.BannerContent
          onClickCapture={onPageBannerClick('bannerBlockV2Body')}
          textAlign={textAlign}
          placeY={placeTextY}
        >
          <Grid horizontalAlign={placeTextX} verticalAlign={placeTextY}>
            <Cell columns={{ laptop: 12 / 1, mobile: 0 }} />
            <Cell columns={{ laptop: 3, mobile: 1 }}>
              <div style={{ color: textColor }}>
                {renderRichTextV2(body as Document, {
                  buttonColor: buttonColorMap[buttonColor],
                  hideMobile: props.hideMobileBodyText
                })}
              </div>
            </Cell>
            <Cell columns={{ laptop: 12 / 1, mobile: 0 }} />
          </Grid>
        </Styles.BannerContent>
      </ConditionalLink>
    </Styles.BannerWrapper>
  );
}
