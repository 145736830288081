import React from 'react';
import ProductCard from '../../../components/ProductCard';
import { ProductCardProps } from 'components/ProductCard/types';
import { ProductMapperResult } from 'shopstory/shopstoryConfig';

export type ShopstoryProductCardProps = {
  data: ProductMapperResult;
} & Pick<ProductCardProps, 'gender' | 'hasColorPicker' | 'hasWishlist' | 'hasLabel' | 'hasPrice' | 'loading'>;

export function ShopstoryProductCard(props: ShopstoryProductCardProps) {
  // key is here to force new instance of ProductCard when props.data is changed. Otherwise, bugs happen.
  return (
    <ProductCard {...props} {...props.data} product={props.data.currentVariant} key={props.data.currentVariant.id} />
  );
}
