import Image from 'next/image';
import React, { ReactNode } from 'react';
import { transform, transformCfVideo } from 'utils/images';
import styled from 'styled-components';
import { HoverMedia, IconExternalLink } from '@aceandtate/ds';
import PreloadInView from 'components/PreloadInView';

const StyledVideo = styled.video`
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
`;

const SIZE = 300;

type HoverMediaType = {
  src: string;
  contentType: string;
  blurData?: string;
  poster?: string;
  alt: string;
};

type Props = {
  hoverMedia: HoverMediaType;
  children: ReactNode;
};

function Media({ hoverMedia }: { hoverMedia: HoverMediaType }) {
  if (hoverMedia.contentType === 'image') {
    return (
      <Image
        placeholder='blur'
        blurDataURL={hoverMedia.blurData}
        src={hoverMedia.src}
        quality={75}
        loader={({ width, quality, src }) => transform(src, { fit: 'scale-down', quality, width })}
        fill
        sizes={`${SIZE}px`}
        style={{
          objectFit: 'cover',
          objectPosition: 'center'
        }}
        alt={hoverMedia.alt}
      />
    );
  }

  if (hoverMedia.contentType === 'video') {
    return <StyledVideo src={transformCfVideo(hoverMedia.src)} autoPlay muted loop />;
  }

  return <>Unknown content type</>;
}

export default function HoverLink({ hoverMedia, children }: Props) {
  return (
    <HoverMedia icon={<IconExternalLink />} media={<Media hoverMedia={hoverMedia} />}>
      <PreloadInView href={hoverMedia.blurData} as='image' />
      {children}
    </HoverMedia>
  );
}
