import { Document } from '@contentful/rich-text-types';
import { Accordion } from '@aceandtate/ds';
import React from 'react';
import type * as ContentfulTypes from 'types/contentful';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';

type Props = ContentfulTypes.FaqQuestion & {
  toggleOpen: (title: string) => void;
  open: boolean;
  [props: string]: any;
};

export default function FAQV2Question({ question, toggleOpen, slug, open, ...props }: Props) {
  return (
    <Accordion
      title={question}
      AdditionalProps={{
        AccordionProps: {
          'data-slug': slug,
          itemProp: 'mainEntity',
          itemScope: true,
          itemType: 'https://schema.org/Question'
        },
        BodyProps: {
          itemProp: 'acceptedAnswer',
          itemScope: true,
          itemType: 'https://schema.org/Answer'
        },
        TitleProps: {
          itemProp: 'name'
        }
      }}
      data-slug={slug}
      open={open}
      onClickItem={() => toggleOpen(slug)}
    >
      <div itemProp='text'>{props.body && renderRichTextV2(props.body as Document)}</div>
    </Accordion>
  );
}
