import React, { ReactNode } from 'react';
import { BLOCKS, INLINES, Block, Inline, Document } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Media from 'components/Media';
import * as Sentry from '@sentry/nextjs';

import * as Styles from './styles';
import { RenderLink } from './renderRichTextV2';

type Node = Block | Inline;
export type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

function renderHeading(node: Node, children: ReactNode) {
  try {
    // nodeTypes look like: "heading-3"
    const headerType = `h${node.nodeType.split('-').pop()}` as HeaderType;
    return (
      <Styles.RichTextTypographyHeading variant={headerType}>
        {/* In order to allow text to be hyphenated and/or words to be
        forced to remain together (via &nbsp; and &shy;) we need to pass
        the text thru dangerouslySetInnerHTML */}
        {React.Children.map(children, child =>
          typeof child === 'string' ? <span dangerouslySetInnerHTML={{ __html: child }} /> : child
        )}
      </Styles.RichTextTypographyHeading>
    );
  } catch (err) {
    return undefined;
  }
}

function renderAssetBlock(node: Node, _: ReactNode) {
  return (
    <Media landscape={{ description: '', file: node.data.target.fields.file, title: node.data.target.fields.title }} />
  );
}

function renderBody(node: Node, children: ReactNode) {
  return (
    <Styles.RichTextTypographyBody variant='bodyM' color='inherit' gutterBottom>
      {children}
    </Styles.RichTextTypographyBody>
  );
}

function renderCaption(node: Node, children: ReactNode) {
  return (
    <Styles.RichTextTypographyBody variant='bodyXS' color='inherit' gutterBottom>
      {children}
    </Styles.RichTextTypographyBody>
  );
}

function guardException(fn: (...args: any[]) => any) {
  return (...args: any[]) => {
    try {
      return fn(...args);
    } catch (err) {
      Sentry.captureException(err, { extra: { args, fn: fn.name } });
      return null;
    }
  };
}

const options: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: renderHeading,
    [BLOCKS.HEADING_2]: renderHeading,
    [BLOCKS.HEADING_3]: renderHeading,
    [BLOCKS.HEADING_4]: renderHeading,
    [BLOCKS.HEADING_5]: renderHeading,
    [BLOCKS.HEADING_6]: renderHeading,
    [BLOCKS.PARAGRAPH]: renderBody,
    [BLOCKS.EMBEDDED_ASSET]: renderAssetBlock,
    [INLINES.HYPERLINK]: guardException(RenderLink),
    [INLINES.ASSET_HYPERLINK]: guardException(RenderLink),
    [INLINES.ENTRY_HYPERLINK]: guardException(RenderLink)
  }
};

type Size = 'medium' | 'small';
// TODO generated contentful types don't always have the Document type set,
// so we are being a bit less strict here
export default function renderRichText(document: Document, size: Size = 'medium') {
  const additionalOptions = {};
  if (size === 'small') {
    additionalOptions[BLOCKS.PARAGRAPH] = renderCaption;
  }

  return documentToReactComponents(document, { renderNode: { ...options.renderNode, ...additionalOptions } });
}
