import { Hideable } from 'blocks/Contentful/styles';
import ConditionalLink from 'components/ConditionalLink';
import React, { ReactElement } from 'react';
import { Flattened } from 'services/contentful/helpers';
import { onPageBannerClick } from 'tracking/utils';
import { LandingPageSideImageBlockV2 } from 'types/contentful';
import { BackgroundPaddingOptions } from '.';
import BannerMedia from '../BannerMedia';
import * as Styles from './styles';

type Props = Flattened<LandingPageSideImageBlockV2> & {
  bannerLink: string;
  videoPlayOptions?: 'autoPlay' | 'autoPlayWithButton';
  backgroundPadding?: BackgroundPaddingOptions;
};

const aspectRatioMap = {
  square: 1,
  tall: 6 / 5
};

const videoFitMap = {
  fill: 'cover',
  fit: 'contain'
};

export default function SideImageMedia({
  backgroundPadding,
  bannerVariant,
  bannerLink,
  media,
  placeholder,
  ...props
}: Props): ReactElement {
  const isVideoMedia = media?.fields.file.contentType.split('/')[0] === 'video';

  return (
    <Styles.SideBannerImageWrap backgroundPadding={backgroundPadding} columns={{ laptop: 12 / 5, mobile: 1 }}>
      <ConditionalLink
        isLink={bannerLink && !isVideoMedia}
        href={bannerLink}
        onClickCapture={onPageBannerClick('sideImageBlockV2Image')}
      >
        {/* Mobile Banner */}
        <Hideable hide={{ laptop: true }}>
          <BannerMedia
            sizes={'100vw'}
            videoFit={videoFitMap[props.videoFit] as 'cover' | 'contain'}
            videoPlayOptions={props.videoPlayOptions}
            imageConfig={{ aspect: aspectRatioMap[bannerVariant], width: 1100 }}
            media={media?.fields}
            poster={placeholder?.fields}
          />
        </Hideable>

        {/* Desktop Banner */}
        <Hideable hide={{ laptop: false, mobile: true }}>
          <BannerMedia
            sizes={'50vw'}
            videoFit={videoFitMap[props.videoFit] as 'cover' | 'contain'}
            videoPlayOptions={props.videoPlayOptions}
            imageConfig={{ aspect: aspectRatioMap[bannerVariant], width: 1100 }}
            media={media?.fields}
            poster={placeholder?.fields}
          />
        </Hideable>
      </ConditionalLink>
    </Styles.SideBannerImageWrap>
  );
}
