import styled from 'styled-components';
import { Cell } from '@aceandtate/ds';
import { brandColors, breakpointRules, grid } from 'styles';
import type { LandingPageBannerBlock } from 'types/contentful';
import type { Flattened } from 'services/contentful/helpers';

type LandingPageBannerProps = Flattened<LandingPageBannerBlock>;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GridItem = styled(Cell)``;

export const BannerContainer = styled.div<LandingPageBannerProps>`
  height: auto;
  position: relative;
`;

const paddingSides = (pad: number) => (props: LandingPageBannerProps) =>
  props.gridWidth === 'fullWidth' ? `0 ${pad}px` : 'unset';

export const BodyContainer = styled.div<LandingPageBannerProps>`
  /* This block is usually used in alignment with the LandingPageCollectionBlock
  Before modifying any of the following margins and paddings, make sure both
  designs using the sames values. */
  margin-top: 15px;
  margin-left: 20px;
  padding: ${paddingSides(30)};
  text-align: ${props => props.bodyTextAlignment || 'left'};

  @media ${breakpointRules.largeMin} {
    margin-left: 0;
    padding: ${paddingSides(60)};
  }

  ${GridItem} > * {
    ${props =>
      props.forceCampaignLinkStyle &&
      `
      margin: 0 !important;
      padding-top: 4px !important;
    `}
  }

  p:empty {
    margin-bottom: 0;
    padding-bottom: ${grid.s};
  }
`;

export const ItemLink = styled.span`
  align-items: center;
  color: ${brandColors.white};
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
`;
