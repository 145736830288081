import { breakpointRules, grid } from '@aceandtate/ds';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import { LandingPageMultiColumnBlockV2 } from 'types/contentful';
import {
  RichTextTypographyV2Body,
  RichTextTypographyV2Caption,
  RichTextTypographyV2Heading
} from 'utils/contentful/stylesRichTextV2';

// Wrapper Styles
export const MultiColumnWrapper = styled.div<Partial<LandingPageMultiColumnBlockV2>>`
  margin-top: ${grid[24]};
  color: ${({ textColor }) => textColor || 'black'};

  @media ${breakpointRules.laptop} {
    margin-top: ${grid[40]} 0;
  }
`;

// Item Styles
export const MultiColImage = styled(Image)`
  height: auto;
  width: 100%;
  display: block;
`;

export const MultiColumnBody = styled.div<{
  layout?: 'big' | 'small';
  displayAsCarousel?: boolean;
}>`
  margin-top: ${grid[12]};

  @media ${breakpointRules.tablet} {
    margin-top: ${grid[24]};
  }

  ${({ displayAsCarousel, layout }) =>
    (displayAsCarousel || layout === 'small') &&
    css`
      padding-right: ${grid[8]};

      @media ${breakpointRules.tablet} {
        padding-right: ${grid[12]};
      }

      @media ${breakpointRules.laptop} {
        padding-right: ${grid[40]};
      }
    `}

  ${({ layout }) =>
    layout === 'small' &&
    css`
      ${RichTextTypographyV2Caption} {
        margin-bottom: ${grid[4]};
      }

      ${RichTextTypographyV2Body},${RichTextTypographyV2Heading} {
        margin-bottom: ${grid[8]};
      }

      @media ${breakpointRules.laptop} {
        ${RichTextTypographyV2Body}, ${RichTextTypographyV2Heading} {
          margin-bottom: ${grid[12]};
        }
      }
    `}

    ${({ displayAsCarousel, layout }) =>
    !displayAsCarousel &&
    layout === 'big' &&
    css`
      margin-top: ${grid[24]};

      ${RichTextTypographyV2Caption} {
        margin-bottom: ${grid[12]};
      }
    `}
`;
