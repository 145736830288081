import React, { JSXElementConstructor, useMemo } from 'react';
import Link from 'next/link';
import { UrlRoute } from 'types/contentful';
import HoverLink from './Partials/HoverMedia';
import { Asset } from 'contentful';
import { ProductMapperResult } from 'shopstory/shopstoryConfig';
import { transform } from 'utils/images';

type CommonLinkProps = {
  Component: JSXElementConstructor<any>;
  componentProps: any;
  values: {
    targetBlank?: boolean;
  };
};
type EffectProps = {
  values: {
    hasHoverMedia?: boolean;
  };
};

type QuickLinkProps = CommonLinkProps &
  EffectProps & {
    values: {
      asset?: Asset;
      url?: string;
    };
  };
export function QuickLink({ Component, componentProps, values }: QuickLinkProps) {
  const { targetBlank, url = '#', asset, hasHoverMedia } = values;
  const mappedResult = useMemo(() => (asset ? effectMappers.Asset(asset) : null), [values.asset]);

  if (hasHoverMedia && mappedResult?.hoverMedia) {
    return (
      <HoverLink hoverMedia={mappedResult.hoverMedia}>
        <Link href={url} target={targetBlank ? '_blank' : undefined}>
          <Component {...componentProps} as='span' />
        </Link>
      </HoverLink>
    );
  }

  return (
    <Link href={url} target={targetBlank ? '_blank' : undefined}>
      <Component {...componentProps} as='span' />
    </Link>
  );
}

type URLRouteLinkProps = CommonLinkProps &
  EffectProps & {
    values: {
      asset?: Asset;
      link?: UrlRoute;
    };
  };
export function URLRouteLinkProvider({ Component, componentProps, values }: URLRouteLinkProps) {
  const { targetBlank, asset, hasHoverMedia } = values;
  const href = values.link?.path || '#';
  const mappedResult = useMemo(() => (asset ? effectMappers.Asset(asset) : null), [values.asset]);

  if (hasHoverMedia && mappedResult?.hoverMedia) {
    return (
      <HoverLink hoverMedia={mappedResult.hoverMedia}>
        <Link href={href} target={targetBlank ? '_blank' : undefined}>
          <Component {...componentProps} as='span' />
        </Link>
      </HoverLink>
    );
  }

  return (
    <Link href={href} target={targetBlank ? '_blank' : undefined}>
      <Component {...componentProps} as='span' />
    </Link>
  );
}

type ProductLinkProps = CommonLinkProps &
  EffectProps & {
    values: {
      product?: ProductMapperResult;
    };
  };
export function ProductLink({ Component, componentProps, values }: ProductLinkProps) {
  const { product, targetBlank, hasHoverMedia } = values;
  const url = product?.currentVariant.sku ? `/${product.currentVariant.sku}` : '#';
  const mappedResult = useMemo(() => (product ? effectMappers.Product(product) : null), [values.product]);

  if (hasHoverMedia && mappedResult?.hoverMedia) {
    return (
      <HoverLink hoverMedia={mappedResult.hoverMedia}>
        <Link href={url} target={targetBlank ? '_blank' : undefined}>
          <Component {...componentProps} as='span' />
        </Link>
      </HoverLink>
    );
  }

  return (
    <Link href={url} target={targetBlank ? '_blank' : undefined}>
      <Component {...componentProps} as='span' />
    </Link>
  );
}

const effectMappers = {
  Asset: (asset: Asset) => {
    const contentType = asset.fields.file.contentType.split('/')[0];

    const hoverMedia = asset.fields.file.url && {
      blurData: transform(asset.fields.file.url, { fit: 'scale-down', quality: 30, width: 128 }),
      contentType,
      src: 'https://' + asset.fields.file.url,
      alt: asset.fields.description || `Ace & Tate | ${contentType}`
    };

    return { hoverMedia };
  },
  Product: ({ currentVariant: product }: ProductMapperResult) => {
    const src = product.images.dynamic?.url || product.images.front?.url || product.images.flat?.url;
    const hoverMedia = src && {
      blurData: transform(src, { fit: 'scale-down', quality: 30, width: 128 }),
      contentType: 'image',
      src,
      alt: `Ace & Tate | ${product.name}`
    };

    return { hoverMedia };
  }
} as const;
