import React, { useRef, useEffect } from 'react';
import { VideoProps } from '@shopstory/react';
import { compressCloudinaryVideo } from 'utils/images';
export function ShopstoryVideo(props: VideoProps) {
  const { src, alt, isPlaying, isMuted } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    // autoplay is disabled in shopstory
    if (!isPlaying) return;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const handleIntersection = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Start playing the video when fully in view
          videoRef.current?.play();
        } else {
          // Pause the video when not in view
          videoRef.current?.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.muted = isMuted;
    }
  }, [isMuted]);

  // for browsers that do not support modern codecs, fallback to h264
  useEffect(() => {
    if (videoRef.current?.readyState >= 2 && videoRef.current.videoHeight === 0) {
      videoRef.current.src = compressCloudinaryVideo(src, 'vc_h264:baseline:3.1');
      videoRef.current.load();
      if (isPlaying) {
        videoRef.current.play();
      }
    }
  }, [videoRef.current]);

  return (
    <video
      loop
      playsInline
      ref={videoRef}
      style={{
        width: '100%',
        height: '100%',
        display: 'block'
      }}
      preload='metadata'
    >
      <source src={compressCloudinaryVideo(src, 'vc_h265:baseline:3.1')} type='video/mp4' />
      <source src={compressCloudinaryVideo(src, 'vc_vp9:baseline:3.1')} type='video/mp4' />
      <source src={compressCloudinaryVideo(src, 'vc_h264:baseline:3.1')} type='video/mp4' />
      {alt}
    </video>
  );
}
