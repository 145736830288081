import styled from 'styled-components';
import { grid, breakpointRules } from '@aceandtate/ds';
import { LegalPageBlock } from 'types/contentful';
import {
  ButtonWrapperV2,
  RichTextTypographyV2Heading,
  RichTextV2ImageWrapper
} from 'utils/contentful/stylesRichTextV2';

export const Body = styled.div<LegalPageBlock>`
  padding-bottom: ${grid[12]};

  p:empty {
    margin-bottom: 0;
    padding-bottom: ${grid[12]};
  }

  ul {
    list-style-position: outside;
    list-style-type: disc;
    margin-left: ${grid[24]};
    margin-bottom: 0;
    padding-left: 0;

    @media ${breakpointRules.desktop} {
      column-count: ${props => (props.columnsLayout === 'doubleColumn' ? 2 : 1)};
    }
  }

  ol {
    margin-bottom: ${grid[24]};
    padding-left: 0;
    @media ${breakpointRules.desktop} {
      column-count: ${props => (props.columnsLayout === 'doubleColumn' ? 2 : 1)};
    }

    /* Creates decimal items automatically */
    list-style-type: none;
    counter-reset: chapter;

    & > li {
      display: table;
      counter-increment: chapter;

      &:before {
        font-size: inherit;
        font-weight: 500;
        padding-right: ${grid[12]};
        content: counters(chapter, '.') '. ';
        display: table-cell;
      }

      ol > li {
        column-count: 1;

        & ol {
          margin-left: 0;
          counter-reset: article;

          & > li {
            counter-increment: article;

            &:before {
              content: counter(article, lower-alpha) ') ';
            }
          }
        }
      }
    }
  }

  ${RichTextTypographyV2Heading} {
    margin-top: ${grid[24]};
  }

  ${ButtonWrapperV2} > a {
    margin-bottom: ${grid[40]};
  }

  ${RichTextV2ImageWrapper} {
    margin-top: ${grid[12]};
    margin-bottom: ${grid[40]};
  }
`;

export const LegalCell = styled.div`
  max-width: 780px;
  width: 100%;
`;

export const LegalWrapper = styled.div<Partial<LegalPageBlock>>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
  display: flex;
  justify-content: center;
`;
